
import { useInternalCorrectionMaintenanceVM } from "presentation/hook/InternalCorrection/useInternalCorrectionMaintenanceVM";
import { useInternalCorrectionMaintenanceTracked } from "presentation/store/InternalCorrection/InternalCorrectionMaintenanceProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import InternalCorrectionTablePanel from "./InternalCorrectionTablePanel";

const InternalCorrectionMaintenance:React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [internalCorrectionState] = useInternalCorrectionMaintenanceTracked();
    const internalCorrectionVM = useInternalCorrectionMaintenanceVM();
    const {isBackMaster} = internalCorrectionState;
    

    useEffect(() => {
        const initialScreen = async() => {   
            setIsLoading(true);
            try {     
                const results = await Promise.allSettled([
                    internalCorrectionVM.loadDropdownOption(),
                    internalCorrectionVM.onSearch(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {
                        console.log(`Table init successful!`);
                    } 
                })
            } catch(error) {
                setIsLoading(false);
            }
        }
        initialScreen().then(() => { 
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
        
    }, [isBackMaster,internalCorrectionVM])


    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
    return <>
        <div className={`main-comp-wrapper`}>
            <SliderPanel
                isOpen={false}
                draggable={false}
                leftSectionWidth={"0%"}
                rightSectionWidth={"100%"}
                leftChildren={<InternalCorrectionTablePanel/>}
                />
        </div>
    </>
}

export default memo(InternalCorrectionMaintenance);