import { InternalCorrectionMaintenanceConstant } from "presentation/constant/InternalCorrection/InternalCorrectionMaintenanceConstant";
import { useInternalCorrectionMaintenanceVM } from "presentation/hook/InternalCorrection/useInternalCorrectionMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useInternalCorrectionMaintenanceTracked } from "presentation/store/InternalCorrection/InternalCorrectionMaintenanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const InternalCorrectionEditPanelTitleBar: React.FC = () => {
    const [internalCorrectionState] = useInternalCorrectionMaintenanceTracked();
    const internalCorrectionVM = useInternalCorrectionMaintenanceVM();
    const messageBarVM = useMessageBarVM();
    const {currentSelectedRow, masterState} = internalCorrectionState;
    const {editingEntity, isAdd, isEditable, isRead} = masterState;
    
    const [anainfoState] = useANAInfoTracked();
    const {allowCreate, allowUpdate} = anainfoState;
    const [isLoading, setIsLoading] = useState(false);
    const currentEntity = isRead? currentSelectedRow : editingEntity;

    const handleReset = useCallback(async () => {
        internalCorrectionVM.onReset();
    }, [internalCorrectionVM]);

    const handleEdit = useCallback(()=>{
        internalCorrectionVM.onEdit(currentEntity);
    },[currentEntity, internalCorrectionVM])

    const handleSave = useCallback(async () => {
        setIsLoading(true);
        try {
            internalCorrectionVM.onSaveClicked();
            const res = await internalCorrectionVM.onSave(currentEntity,isAdd);
            if(!!!res || !res.success){
                messageBarVM.showWarining(res?.data??'Save data failed.');
            }else {
                internalCorrectionVM.onClose();
                setIsLoading(true);
            }
        } catch (error) {
            messageBarVM.showError('Save data failed.');
        }finally{
            setIsLoading(false)
        }
        
    },[currentEntity, isAdd, messageBarVM, internalCorrectionVM]);

    return <Sidebarheader style={{width: '100%'}}>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{title: InternalCorrectionMaintenanceConstant.CATEGORY},
                { title: InternalCorrectionMaintenanceConstant.TITLE }]}onCurrentClick={()=>{}}>
            </HPHBreadcrumb>
        </Breadcrumb>
        <StyledAction>
            {(!isAdd && (isEditable && isRead)) &&
                <>
                    {/* Edit Button */}
                    {
                        (
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                    }
                    {/* Close Button */}
                    <IconButton fileName='Icon-cross' size='medium' toolTipText={'Close'} toolTipArrow={false} onClick={internalCorrectionVM.onClose} />
                </>}
            {(isAdd || (isEditable && !isRead)) &&
                <>
                    {/* Reset Form Button */}
                    <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />
                    {/* Close Button */}
                    <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={internalCorrectionVM.onClose} />
                    {/* Save Button */}
                    {
                        (
                            (isAdd && allowCreate) ||
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />
                    }
                </>}
        </StyledAction>
    </Sidebarheader>
}

export default memo(InternalCorrectionEditPanelTitleBar);