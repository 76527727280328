
import { useInternalCorrectionMaintenanceTracked } from "presentation/store/InternalCorrection/InternalCorrectionMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import InternalCorrectionMaintenance from "./InternalCorrectionMaintenance";
import { InternalCorrectionMaintenanceFormPanel } from "./InternalCorrectionMaintenanceFormPanel";

export const InternalCorrectionMasterView: React.FC = () => {
    const [internalCorrectionState] = useInternalCorrectionMaintenanceTracked();
    const { isAdd, isEditable } = internalCorrectionState.masterState;

    return <>
        <div className="main-comp-wrapper">
            <InternalCorrectionMaintenance/>
            {
                ((isAdd || isEditable) && 
                <SliderPanel
                    isOpen={false}
                    leftSectionWidth={"100%"}
                    rightSectionWidth={"0%"}
                    leftChildren={<InternalCorrectionMaintenanceFormPanel/>}
                />
                )
            }
        </div>
    </>
}